export function isDom(target: any) {
  const usefullFunc =
    typeof HTMLElement === 'object'
      ? function (obj: any) {
          return obj instanceof HTMLElement;
        }
      : function (obj: any) {
          return (
            obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string'
          );
        };

  if (target) {
    return usefullFunc(target);
  }
  return false;
}

export function classContains(target: any, className: string) {
  if (isDom(target) && className !== '') {
    return target.classList.contains(className);
  }
  return false;
}

export function getComputedStyle(target: any, prop: string) {
  if (isDom(target) && prop !== '') {
    return window.getComputedStyle(target).getPropertyValue(prop);
  }
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import EcyWatermark from '@mi/ecy-watermark';

import App from './App.vue';
import routes from './routes';
import startQiankun, { setupQiankun } from './micro';
import getApps from './micro/apps';
import { RegistrableApp } from './micro/es/interfaces';
import { initSentry } from './utils/sentry';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/base.css';
import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/icon.css';
import 'element-ui/lib/theme-chalk/dropdown.css';
import 'element-ui/lib/theme-chalk/message-box.css';
import { initPerformanceObserve } from './utils/use-performance-onetrack'
import { initOnetrack } from './utils/onetrackStat.js';


Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(EcyWatermark);

Vue.config.productionTip = false;
// 重写 vuerouter.push方法，不然会报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};

const createRouter: any = () =>
  new VueRouter({
    // base: process.env.BASE_URL,
    base: '/',
    mode: 'history',
    routes
  });
const router: any = createRouter();

function initRouter(apps: RegistrableApp[]) {
  router.beforeEach(async (to: any, from: any, next: any) => {
    if (to.meta.title) {
      document.title = '零售通PC';
    }

    const isMatch = apps.some((app) => {
      const reg = new RegExp(`${app.activeRule}/?`);
      return reg.test(to.path);
    });

    // 是否匹配主应用路由
    if (to.name && to.meta.requireAuth === false) {
      next();
      // 若子应用路由匹配不上则返回 404
    } else if (!isMatch && apps.length) {
      console.log('to', to);
      next({ name: '404', query: { source_url: encodeURIComponent(to.fullPath) } });
      // path 可以匹配 主应用路由 或者 子应用路由时，判断是否含有cookie值
    } else {
      if (
        !from.fullPath.startsWith('//') &&
        !to.fullPath.startsWith('//') &&
        from.fullPath !== to.fullPath
      ) {
        const activeSubApp = '/' + to.path.split('/')[1];
        const subApp: any = apps.find((app) => app.activeRule === activeSubApp);
        // console.log(
        //   `准备埋点PV：from:${from.fullPath} to ${to.fullPath}`,
        //   subApp?.props.projectName
        // );
        window.onetrack('track', 'pv', {
          sub_app: subApp?.props.projectName
        });
      }
      startQiankun({
        prefetch: false
      });
      next();
    }
  });
}

function setup() {
  getApps().then((apps: RegistrableApp[]) => {
    if (Array.isArray(apps)) {
      initSentry('', {});
      initRouter(apps);
      setupQiankun(apps);
    }

    mountApp();
  });
}

function mountApp() {
  initOnetrack();
  // 初始化质量指标函数
  if (window.location.href.includes('main-mistore')) {
    initPerformanceObserve();
  }

  // eslint-disable-next-line
  (window as any)._vueRoot = new Vue({
    router,
    // @ts-ignore
    render: (h) => h(App)
  }).$mount('#main-app');

}

setup();

import Vue from 'vue';
import { init, close, Event as SentryEvent } from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const env = process.env.NODE_ENV;

const matchUrlAppName = (url = '') => {
  // 从url里取子应用tag
  const _url = url!.replace(window.origin, '');
  return /^\/main-/g.test(_url) ? _url.replace(/^\/main-/ig, '').replace(/\/.*?$/ig, '').replace(/#/g, '') : '';
}

export async function initSentry(appName: string, { app = Vue, release = process.env.VUE_APP_RELEASE_VERSION }) {
  const excuteSentry = ['production', 'pre', 'test'].includes(env);

  if (excuteSentry) {
    await close(1);

    // 初始化Sentry
    init({
      Vue: app,
      dsn: 'https://73d47cee7f29467fb5527e4415df3616@sentry.d.xiaomi.net/759',
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: ['localhost'],
        })
      ],
      tracesSampleRate: /test|pre/g.test(env) ? 1 : 0.5,
      environment: env,
      release,
      // https://docs.sentry.io/platforms/javascript/configuration/options/#auto-session-tracking
      autoSessionTracking: false,
      ignoreErrors: [
        // 过滤element-ui表格问题
        /^ResizeObserver loop/,
        // 过滤由于缓存导致 ChunkLoadError
        'Loading chunk',
        'Loading CSS chunk',
        // 过滤没有reject的Promise错误
        'Non-Error promise rejection captured',
        // 过滤qiankun子应用加载失败报错
        /application '\w*-?\w*' died in status/
      ],
      // 将sentry上报事件tag设置为子应用名称
      beforeSend(event: SentryEvent) {
        event.tags = event.tags || {};
        event.tags.nr_qiankun = appName || matchUrlAppName(event.request!.url) || 'main';
        return event;
      }
    });
  }
}

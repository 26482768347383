const env:string = process.env.NODE_ENV;
// 兼容非qiankun 应用，为这些应用提供首页入口
const urlMap: {[x:string]: any} = {
    development: {
        luban: 'https://luban-be.test.mi.com/',
        mkt: 'https://mkt-be.test.mi.com/home/dash/index',
        bpm: 'http://bpm-nr.test.be.mi.com',
        upc: 'http://upc-nr.test.be.mi.com/',
        jianyu:'http://support-be.test.mi.com/'
    },
    test: {
        luban: 'https://luban-be.test.mi.com/',
        mkt: 'https://mkt-be.test.mi.com/home/dash/index',
        bpm: 'http://bpm-nr.test.be.mi.com',
        upc: 'http://upc-nr.test.be.mi.com/',
        jianyu:'http://support-be.test.mi.com/'
    },
    pre: {
        luban: 'https://luban.be.mi.com/',
        mkt: 'https://mkt.be.pre.mi.com/home/dash/index',
        bpm: 'https://bpm-nr.be.mi.com/',
        upc: 'https://upc-nr.be.mi.com/dashboard/index',
        jianyu:'https://support.be.mi.com/'
    },
    production: {
        luban: 'https://luban.be.mi.com/',
        mkt: 'https://mkt.be.mi.com/home/dash/index',
        bpm: 'https://bpm-nr.be.mi.com/',
        upc: 'https://upc-nr.be.mi.com/dashboard/index',
        jianyu:'https://support.be.mi.com/'
    }
}

const getExtraUrl = (pathName:string) => {
    const systemName = pathName.replace('/main-','');
    const urlObj = urlMap[env];
    if (!urlObj) {
        return false;
    }
    return urlObj[systemName] || false;
}


export default getExtraUrl;

import { get } from './http';
import { ProjectListDataModal, ProjectItemModal, SubProjectModal } from '../types/modal';

export interface IResponse<T> {
  code: number;
  msg: string;
  data: T;
}

export function apiGetUserInfo() {
  return get('/nr-qiankun-center/sso/getUserInfo');
}

export function apiGetUserProjectListByGroup(parentTag: string) {
  return get(`/nr-qiankun-center/project/listUserCategoryProject?parentTag=${parentTag}`).then(
    (res: any): IResponse<ProjectListDataModal> => {
      if (res.code === 0) {
        res.data &&
          res.data.forEach((project: ProjectItemModal) => {
            project.data &&
              project.data.forEach((subItem: SubProjectModal) => {
                delete subItem.url;
              });
          });
      }
      return res;
    }
  );
}

// 获取用户拥有的系统角色列表
export const apiGetRoleList = (projectTag = '') => {
  return get(`/nr-qiankun-center/role/listRoleByAccount?projectTag=${projectTag}`).then(
    (res: any) => {
      if (res.code === 0) {
        return res.data;
      } else {
        return [{ roleName: '超级管理员', roleKey: 'super_admin' }];
      }
    }
  );
};

export const apiSwitchRole = (projectTag: string, roleKey: string | number) => {
  return get(`/nr-qiankun-center/role/saveCurrentRole?projectTag=${projectTag}&roleKey=${roleKey}`);
};


import { Component, Vue } from 'vue-property-decorator';
import actions from '@/shared/actions';
// import { IProject } from "@/types/interface";
import getExtraUrl from '@/utils/extraUrl';

export interface IProject {
  addTime: number;
  approvers: string;
  icon: string;
  projectId: number;
  projectName: string;
  projectTag: string;
  showType: number;
  url?: string;
}

interface SubProjectModal {
  projectId: number;
  projectName: string;
  projectTag: string;
  projectDesc: string;
  icon: string;
  url: string;
  showType: number;
  key?: string;
  title?: string;
  path?: string;
  [propName: string]: any;
}

interface ProjectItemModal {
  categirtId: number;
  name: String;
  data: Array<SubProjectModal>;
}

interface ProjectInfo {
  roleName?: string;
  projects?: ProjectItemModal[];
  userInfo?: UserInfo;
}

interface UserInfo {
  account: string;
  email: string;
  userName: string;
  [key: string]: any;
}

@Component({})
export default class Home extends Vue {
  now: number = Date.now();
  projects: SubProjectModal[] = [];

  mounted() {
    actions.onGlobalStateChange((state: ProjectInfo): void => {
      if (!state.projects) {
        return;
      }
      const projects: Array<ProjectItemModal> = state!.projects || [];
      const knownProjectIds = [88, 38, 29, 57];
      const knowProjects = new Array(4);
      const otherProjects: SubProjectModal[] = [];
      projects.forEach((item: ProjectItemModal) => {
        item.data.forEach((subItem: SubProjectModal) => {
          if (knownProjectIds.includes(subItem.projectId)) {
            const index = knownProjectIds.indexOf(subItem.projectId);
            knowProjects[index] = subItem;
          } else {
            otherProjects.push(subItem);
          }
        });
      });

      this.projects  = [...knowProjects.filter(v => !!v), ...otherProjects];

      // 获取所有子应用
      // this.projects = projects.reduce((total: SubProjectModal[], item: ProjectItemModal) => {
      //   return (total = total.concat(item.data));
      // }, []);
    }, true);
  }

  gotoSubApp(projectTag: string) {
    const activeRule = `/main-${projectTag}`;
    if (getExtraUrl(activeRule)) {
      window.open(getExtraUrl(activeRule));
    } else {
      this.$router.push(`/${activeRule}`);
    }
  }

  showIcon(icon: string) {
    return icon && icon.length > 0 && icon.startsWith('http');
  }
}

import { debounce } from 'lodash-es';
import ttiPolyfill from 'tti-polyfill';

export const initPerformanceObserve = () => {
  const debounceSubmitLcpData = debounce((startTime: number) => {
    window.onetrack('track', 'LCP', {
      tip: '636.0.0.0.24823',
      Quality_Index_Value: startTime
    });
  }, 10000);
  try {
    const observer = new PerformanceObserver((list) => {
      for (const entryObj of list.getEntriesByType('navigation')) {
        const {
          connectStart,
          connectEnd,
          domainLookupStart,
          domainLookupEnd,
          responseStart,
          requestStart,
          domComplete,
          loadEventStart,
          fetchStart,
          domInteractive,
          responseEnd
        }: any = entryObj;
        const timeDns = domainLookupEnd - domainLookupStart;
        const timeTcp = connectEnd - connectStart;
        const timeWaiting = responseStart - requestStart;
        const domCompleted = domComplete;
        const pageload = loadEventStart - fetchStart;
        const domParse = domInteractive - responseEnd;
        window.onetrack('track', 'DNS', {
          tip: '636.0.0.0.24825',
          Quality_Index_Value: timeDns
        });
        window.onetrack('track', 'TCP', {
          tip: '636.0.0.0.24826',
          Quality_Index_Value: timeTcp
        });
        window.onetrack('track', 'Waiting', {
          tip: '636.0.0.0.24829',
          Quality_Index_Value: timeWaiting
        });
        window.onetrack('track', 'DomComplete', {
          tip: '636.0.0.0.24827',
          Quality_Index_Value: domCompleted
        });
        window.onetrack('track', 'PageLoad', {
          tip: '636.0.0.0.24830',
          Quality_Index_Value: pageload
        });
        window.onetrack('track', 'DomParse', {
          tip: '636.0.0.0.24828',
          Quality_Index_Value: domParse
        });
      }
      for (const entry of list.getEntries()) {
        const { name, startTime, entryType } = entry;
        if (name === 'first-paint') {
          window.onetrack('track', 'FP', {
            tip: '636.0.0.0.24819',
            Quality_Index_Value: startTime
          });
        }
        if (name === 'first-contentful-paint') {
          window.onetrack('track', 'FCP', {
            tip: '636.0.0.0.24822',
            Quality_Index_Value: startTime
          });
        }
        if (entryType === 'largest-contentful-paint') {
          debounceSubmitLcpData(startTime);
        }
      }
    });
    // observer.observe({
    //   entryTypes: ['paint', 'largest-contentful-paint', 'navigation'],
    //   buffered: true
    // });
    observer.observe({
      type: 'paint',
      buffered: true
    });
    observer.observe({
      type: 'largest-contentful-paint',
      buffered: true
    });
    observer.observe({
      type: 'navigation',
      buffered: true
    });
    // TTI
    ttiPolyfill.getFirstConsistentlyInteractive().then((tti: any) => {
      window.onetrack('track', 'TTI', {
        tip: '636.0.0.0.24824',
        Quality_Index_Value: tti
      });
    });
  } catch (e) {
    console.log('监听报错', e?.message ? e.message : e);
  }
};

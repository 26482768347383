function loadCDN(url, type) {
  type = type || 'js';
  if (type === 'js') {
    const s = document.createElement('script');
    s.src = url;
    document.body.appendChild(s);
  } else if (type === 'css') {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', url);
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

function composedPath(el) {
  const path = [];

  while (el) {
    path.push(el);

    if (el.tagName === 'HTML') {
      // path.push(document);
      // path.push(window);

      return path;
    }

    el = el.parentElement;
  }
}

/**
 * 上报点击事件
 * 为避免无效点击，重写addEventListener
 */

function getDomPath(el) {
  const target = el;
  if (!target) return '';
  const tagName = target.tagName.toLowerCase();
  const idStr = target.id ? `#${target.id}` : '';
  const classNameStr = target.classList.toString();
  const classStr = classNameStr ? `.${classNameStr.split(' ').join('.')}` : '';
  return tagName + idStr + classStr;
}

function getDomFullPath(event) {
  // const path = event.path || (event.composedPath && event.composedPath());
  const path = composedPath(event.target);
  if (path) {
    const domPath = path.reverse();
    return domPath.map((dom) => getDomPath(dom)).join(' > ');
  }
  return '';
}

function autoTrackClick(fn, event) {
  // console.log('click------', event.target);
  window.onetrack('track', 'click', {
    sub_app: document.title.split('-')[0],
    dom_path: getDomFullPath(event),
    dom_text: event.currentTarget.innerText
  });
  if (typeof fn === 'function') {
    fn(event);
  }
}

function rewriteAddEventListener() {
  // 扩展监听事件
  Element.prototype.realAddEventListener = Element.prototype.addEventListener;
  Element.prototype.addEventListener = function (a, b, c) {
    if (a === 'click') {
      this.realAddEventListener(a, (e) => autoTrackClick(b, e), c);
    } else {
      this.realAddEventListener(a, b, c);
    }
  };
  // 扩展移出监听事件
  Element.prototype.realRemoveEventListener = Element.prototype.removeEventListener;
  Element.prototype.removeEventListener = function (a, b, c) {
    if (a === 'click') {
      this.realRemoveEventListener(a, (e) => autoTrackClick(b, e), c);
    } else {
      this.realRemoveEventListener(a, b, c);
    }
  };
}

/**
 * 设置onetrack公共参数，getUserInfo之后调用
 * @param {*} userInfo
 */
export function setOnetrack(userInfo) {
  const params = {
    user_name: userInfo.account,
    user_mid: userInfo.miID,
    user_email: userInfo.email,
    system: '零售通PC'
  };
  window.onetrack('set', params);
}

export function initOnetrack() {
  loadCDN('//ssl-cdn.static.browser.mi-img.com/mistat-data/onetrack/onetrack.js');

  window.onetrack =
    window.onetrack ||
    function (...args) {
      // eslint-disable-next-line no-undef
      (onetrack.q = onetrack.q || []).push(args);
    };

  // eslint-disable-next-line no-undef
  onetrack('init', '31000000556');

  // rewriteAddEventListener();
}

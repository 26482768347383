
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiGetRoleList, apiGetUserInfo, apiSwitchRole } from '@/api/user';
import actions from '@/shared/actions';
import clickoutside from '@/directives/clickoutside/clickoutside';
import { getLoginUrl } from '@/utils/helper';
import { setOnetrack } from '@/utils/onetrackStat.js'
import getExtraUrl from '@/utils/extraUrl';
import { Route } from 'vue-router';
interface IMenu {
  key: string;
  title: string;
  path: string;
  projectId: number;
}
interface SubProjectModal {
  projectId?: number;
  projectName?: string;
  projectTag?: string;
  projectDesc?: string;
  icon?: string;
  url?: string;
  showType?: number;
  key?: string;
  title?: string;
  path?: string;
  [propName: string]: any;
}

interface ProjectItemModal {
  categirtId: number;
  name: String;
  data: Array<SubProjectModal>;
}

@Component({
  directives: {
    clickoutside: clickoutside
  }
})
export default class Topbar extends Vue {
  //watch路由改变标题
  @Watch('$route', { immediate: true })
  onPathChange(newRoute: Route, oldRoute: Route) {
    this.setTitleInMenus(newRoute.path);
    this.getUserRoles(newRoute.path, oldRoute ? oldRoute.path : '');
  }

  // 监听子应用列表
  @Watch('originMenus')
  originMenusChange(val: []) {
    if (!val || !val.length) {
      return false;
    }
    this.setTitleInMenus((this as any).$route.path);
  }

  @Watch('searchTitle', { immediate: true })
  getShowMenus(newValue: string) {
    if (newValue == '') {
      this.showMenus = this.originMenus;
    } else {
      this.showMenus = this.originMenus.map((item: ProjectItemModal): ProjectItemModal => {
        const filterArr: Array<SubProjectModal> = item.data.filter(
          (subItem: SubProjectModal) => subItem.title && subItem.title.indexOf(newValue) > -1
        );
        return {
          ...item,
          data: filterArr
        };
      });
    }
  }

  get currentRoleName() {
    const role = this.userRoles.find((item: any) => item.isCurrentRole);
    return role ? role.roleName : '';
  }

  userInfo: any = {};
  userRoles: any[] = [];
  selectKey: string = '';
  isShowMask: boolean = false;
  isShowConfig: boolean = false;

  originMenus: Array<ProjectItemModal> = [];
  showMenus: Array<ProjectItemModal> = [];
  searchTitle: string = ''; // title查询值
  curTitle: string = '';
  env: string = process.env.NODE_ENV;

  created() {
    this.getUserInfo();
  }

  mounted() {
    actions.onGlobalStateChange((state) => {
      this.originMenus = this.normalizeMenus(state.projects);
      this.showMenus = this.originMenus;
    }, true);
    actions.offGlobalStateChange();
  }

  setTitleInMenus(path: String) {
    if (path !== '/') {
      path = '/' + path.split('/')[1];
    }
    this.originMenus.forEach((item: ProjectItemModal) => {
      item.data.forEach((subItem: SubProjectModal) => {
        if (subItem.path !== path) {
          return false;
        }
        this.curTitle = subItem.title || '';
        this.selectKey = subItem.key || '';
      });
    });
  }

  normalizeMenus(projects: Array<ProjectItemModal> /* IProject[] */ = []): Array<ProjectItemModal> {
    const menus: Array<ProjectItemModal> = [
      {
        categirtId: 0,
        name: '主应用',
        data: [
          {
            title: '首页',
            key: 'home',
            path: '/'
          }
        ]
      }
    ];
    projects.forEach((item: ProjectItemModal) => {
      item.data
        .filter(
          (subItem: SubProjectModal) =>
            // subItem.showType == 2 &&
            !(this.userInfo.userType == 3 && subItem.projectId == 7)
        )
        .forEach((subItem: SubProjectModal) => {
          subItem.key = subItem.projectTag;
          subItem.title = subItem.projectName;
          const extraUrl = getExtraUrl('/main-' + subItem.projectTag);
          if (extraUrl) {
            subItem.is_blank = '_blank';
            subItem.path = extraUrl;
          } else {
            subItem.path = '/main-' + subItem.projectTag;
          }
        });
      menus.push(item);
    });
    return menus;
  }

  //获取用户信息  项目列表
  async getUserInfo() {
    const res: any = await apiGetUserInfo();
    if (res.code === 0) {
      res.data.avatar =
        res.data.avatar ||
        'https://work.test.mi.com/retailrevolution-pc/img/user_icon.dcd7cc3b.png';
      let userInfo = res.data;
      this.userInfo = res.data;
      setOnetrack(res.data);

      //用户信息存入setGlobalState供子项目使用
      actions.setGlobalState({ userInfo });
    } else {
      const redirect = encodeURI(window.location.href);
      setTimeout(() => {
        this.$router.push({
          name: 'login',
          query: { redirect: redirect }
        });
      }, 1000);
      return false;
    }
  }

  async handleCommand(roleKey: string | number) {
    console.log('切换角色', roleKey);
    const projectTag = this.getProjectTagFromPath(this.$route.path);
    // const activeRule = `/main-${projectTag}`;
    // this.$router.push(`/${activeRule}`);
    try {
      const { code }: any = await apiSwitchRole(projectTag, roleKey);
      if (code === 0) {
        location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  }

  getProjectTagFromPath(path: string) {
    if (!path) return '';
    return path.split('/')[1].split('main-')[1];
  }

  /**
   * 获取用户角色
   * newPath '/main-policy-center/xxx/xx'
   */
  async getUserRoles(newPath: string, oldPath: string) {
    const newProjectTag = this.getProjectTagFromPath(newPath);
    const oldProjectTag = this.getProjectTagFromPath(oldPath);
    if (!newProjectTag) {
      this.userRoles = [];
      return;
    }

    if (newProjectTag === oldProjectTag) {
      return;
    }

    try {
      const res = await apiGetRoleList(newProjectTag);
      this.userRoles = res;
      actions.setGlobalState({ userRoles: res });
    } catch (error) {
      console.error('获取用户角色失败', error);
    }
  }

  showMenu() {
    this.isShowMask = true;
  }

  closeMenu() {
    this.isShowMask = false;
  }

  showConfig() {
    this.isShowConfig = true;
  }

  hideConfig() {
    this.isShowConfig = false;
  }

  logout() {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const loginUrl = getLoginUrl();
    window.location.href = `${baseUrl}/nr-qiankun-center/sso/passport/logout?followup=${encodeURI(
      loginUrl
    )}`;
  }
  goCenter() {
    window.open('/main-taskcenter');
  }
}

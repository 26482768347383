import { RegistrableApp } from './es/interfaces';
import { apiGetUserProjectListByGroup, IResponse } from '@/api/user';
import { ProjectListDataModal, SubProjectModal, ProjectItemModal } from '../types/modal';
import actions from '@/shared/actions';
import { initSentry } from '../utils/sentry';

const env = process.env.NODE_ENV;
let localPorts: { [key: string]: number } = {
  _default: 9999
};

const localDefines = require(`../../config/${env === 'development' ? 'local.' : ''}defines`);
localPorts = localDefines.localPorts;

/**
 * 获取子应用入口
 * @param pathName 子应用独立运行时对应的路径，需和子应用中 nginx 配置保持一致
 */
const getEntry = (pathName: string): string => {
  let childAppEntry = '';
  if (env === 'development') {
    // 必须启动相应子应用
    childAppEntry = `//localhost:${localPorts[pathName] || localPorts['_default']}`;
  } else if (env === 'test') {
    childAppEntry = `//work.test.mi.com/${pathName}/`;
  } else if (env === 'pre') {
    childAppEntry = `//work.pre.mi.com/${pathName}/`;
  } else if (env === 'production') {
    childAppEntry = `//work.mi.com/${pathName}/`;
  }
  return childAppEntry;
};

/**
 * 生成子应用配置
 * @param subAppName 子应用 vue.config.js 中的 configureWebpack.output.library 值，不可重复
 */
const genChildApp = (subAppName: string, subAppProjectName: string): RegistrableApp => {
  return {
    name: subAppName,
    entry: getEntry(subAppName),
    container: '#frame',
    activeRule: `/main-${subAppName}`,
    props: {
      projectName: subAppProjectName,
      initSentry: async({app, release} : {app: any; release: any}) => {
        await initSentry(subAppName, {app, release});
      }
    }
  };
};

async function getApps() {
  let apps: RegistrableApp[] = [];
  const res: IResponse<ProjectListDataModal> = await apiGetUserProjectListByGroup('qiankun-admin');
  if (res.code !== 0) return apps;
  // 生产环境过滤 “建设中” 标签的项目
  if (process.env.NODE_ENV === 'production') {
    res.data = res.data.filter((item) => (item as any).categoryId !== 9);
}
  actions.setGlobalState({ projects: res.data });
  // 添加子应用
  res.data.forEach((item: ProjectItemModal) => {
    item.data.forEach((subItem: SubProjectModal) => {
      apps.push(genChildApp(subItem.projectTag, subItem.projectName));
    });
  });

  return apps;
}

export default getApps;


import { Component, Vue, Watch } from 'vue-property-decorator';
import { MessageBox } from 'element-ui';

// 已迁移应用列表
const transferredApps = [
  'main-retail-train-live',
  'main-adspacesystem',
  'main-store-info-collect',
  'main-store-assets',
  'main-image-audit'
];

const newDomainName: any = {
  development: 'https://pt-work.test.mi.com',
  test: 'https://pt-work.test.mi.com',
  pre: 'https://pt-work.pre.mi.com',
  production: 'https://pt.work.mi.com'
};

@Component
export default class NotFound extends Vue {
  sourceUrl: string = '';
  message: string = '咦~页面不见了~';
  newUrl: string = '';

  @Watch('$route', { immediate: true, deep: true })
  onRouteNameChange(newVal: any /** Route */) {
    console.log('newVal', newVal);
    if (newVal?.query?.source_url) {
      this.sourceUrl = decodeURIComponent(newVal.query.source_url);
      this.setMessage();
    }
  }

  setMessage() {
    for (let i = 0; i < transferredApps.length; i++) {
      const element = transferredApps[i];
      const regRule = new RegExp(`^/${element}`);

      if (regRule.test(this.sourceUrl)) {
        const nodeEnv: string = process.env.NODE_ENV || '';

        this.message = '域名变更，请登录并保存新地址';
        this.newUrl = `${newDomainName[nodeEnv]}${this.sourceUrl}`;

        MessageBox({
          title: '域名变更提示',
          message: `${this.message}`,
          confirmButtonText: '立即前往',
          type: 'error'
        }).then(() => {
          window.location.href = this.newUrl;
        });

        break;
      }
    }
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator';
import actions from '@/shared/actions';
import Topbar from '@/components/topbar/index.vue';

@Component({
  components: {
    Topbar
  }
})
export default class App extends Vue {
  private showTopbar: boolean = false;
  private isHome: boolean = false;
  private watermarkText: string = '';
  private isNewThemeApp: boolean = false;

  @Watch('$route', { immediate: true, deep: true })
  onRouteNameChange(newVal: any /** Route */) {
    this.showTopbar = newVal.path !== '/login';
    this.isHome = newVal.path === '/';
    console.log(newVal);
    this.isNewThemeApp = !newVal.path.includes('main-taskcenter');
  }

  mounted() {
    actions.onGlobalStateChange((state) => {
      if (state.userInfo) {
        this.watermarkText = `${state.userInfo.userName}  ${state.userInfo.mobile.slice(-4)}`;
      }
    }, true);
  }
}
